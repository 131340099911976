import logo from './res/IMG_3346.jpg';
import resume from './res/Arvand_Marandi_Resume (4).pdf';
import githubIcon from './res/github-icon.png';
import linkedinIcon from './res/linkedin-icon.png';
import emailIcon from './res/email-icon.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <main>
        <img src={logo} className="App-logo" alt="logo" />

        <p className="header-text">Arvand Marandi</p>
        <p className="about-text">
          Amateur soccer player,{' '}
          <a href="https://www.youtube.com/watch?v=dQw4w9WgXcQ" target="_blank" rel="noopener noreferrer">music enthusiast</a>
          , and computer science student at Gonzaga University
        </p>

        <a href={resume} target="_blank" rel="noopener noreferrer" className="resume-button">View Resume</a>

        <a href="https://github.com/arvmarandi" target="_blank" rel="noopener noreferrer" className="github-button">
          <img src={githubIcon} alt="GitHub" style={{ width: '75px', height: '75px' }} />
        </a>

        <a href="https://www.linkedin.com/in/arvand-marandi/" target="_blank" rel="noopener noreferrer" className="linkedin-button">
          <img src={linkedinIcon} alt="LinkedIn" style={{ width: '75px', height: '75px' }} />
        </a>

        <a href="mailto:arv.marandi@gmail.com" target="_blank" rel="noopener noreferrer" className="email-button">
          <img src={emailIcon} alt="Email" style={{ width: '75px', height: '75px' }} />
        </a>

      </main>
    </div>
  );
}

export default App;
